import { render, staticRenderFns } from "./SnapNavigationLayout.vue?vue&type=template&id=717e4684&scoped=true&"
import script from "./SnapNavigationLayout.vue?vue&type=script&lang=js&"
export * from "./SnapNavigationLayout.vue?vue&type=script&lang=js&"
import style0 from "./SnapNavigationLayout.vue?vue&type=style&index=0&id=717e4684&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "717e4684",
  null
  
)

export default component.exports